import Vue from "vue";
// 1
const Bus = new Vue({
    beforeCreate() {
        this.$on("busData", data => {
            localStorage.setItem("StorageData", JSON.stringify(data));
        });
    },
    data() {
        return {
            busData: "",
            busStorageData: JSON.parse(localStorage.getItem("StorageData"))
        };
    },
    created() {
        this.$on("busData", data => {
            this.busData = data;
        });
    },
    destroyed() {
        localStorage.removeItem("StorageData");
    }
});

export default Bus;

